
<template>
	
	<div class="meritWrap" style="background:#fff;">
        <div class="innerWrap">
            <h2 class="pt20 mb20">개인정보 처리방침</h2>
            <div class="rulez">
                <p><strong class="titleSt">1. 개인정보의 처리 및 이용 목적</strong><br><br>
                (주)디엑스플랫폼은 다음의 목적을 위하여 개인정보를 처리하고 있으며, 다음의 목적 이외의 용도로는 이용하지 않습니다.<br> - 고객 가입의사 확인, 고객에 대한 서비스 제공에 따른 본인 식별.인증, 회원자격 유지.관리, 물품 또는 서비스 공급에 따른 금액 결제, 물품 또는 서비스의 공급.배송 등<br>
                <br>
                1. 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산<br>
                컨텐츠 제공, 구매 및 요금 결제
                <br><br>
                2. 회원관리<br>
                회원제 서비스 제공, 개인식별, 가입의사 확인, 추후 법정 대리인 본인확인, 분쟁 조정을 위한 기록보존, 불만처리 등 민원처리, 고지사항 전달, 회원탈퇴의사의 확인<br><br>

                <br><strong class="titleSt">2. 개인정보의 처리 및 보유 기간</strong><br>
                <br>①
                (주)디엑스플랫폼은 정보주체로부터 개인정보를 수집할 때 동의 받은 개인정보 보유․이용기간 또는 법령에 따른 개인정보 보유․이용기간 내에서 개인정보를 처리․보유합니다.<br><br>

                1. 회사 내부 방침에 의한 정보보유 사유<br><br>
                • 부정이용기록<br>
                보존 이유 : 부정 이용 방지<br>
                보존 기간 : 1년<br><br>
                2. 관련법령에 의한 정보보유 사유<br>
                상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.<br><br>
                • 계약 또는 청약철회 등에 관한 기록<br>
                보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률<br>
                보존 기간 : 5년<br><br>
                • 대금결제 및 재화 등의 공급에 관한 기록<br>
                보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률<br>
                보존 기간 : 5년<br><br>
                • 전자금융 거래에 관한 기록<br>
                보존 이유 : 전자금융거래법<br>
                보존 기간 : 5년<br><br>
                • 소비자의 불만 또는 분쟁처리에 관한 기록<br>
                보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률<br>
                보존 기간 : 3년<br><br>
                • 웹사이트 방문기록<br>
                보존 이유 : 통신비밀보호법<br>
                보존 기간 : 3개월<br><br>

                <br>
                </p>
                <p class="lh6 bs4"><strong class="titleSt">3. 정보주체의 권리,의무 및 그 행사방법</strong><br><br>
                이용자는 개인정보주체로서 다음과 같은 권리를 행사할 수 있습니다.</p>
                <p class="ls2">- 이용자는 언제든지 ‘aptok 로그인 &gt; 기본정보관리 &gt; 회원정보’에서 자신의 개인정보를 조회하거나 수정할 수 있습니다<br>
                <br>- 이용자는 언제든지 ‘회원탈퇴’ 등을 통해 개인정보의 수집 및 이용 동의를 철회할 수 있습니다.<br>
                <br>- 본 사이트는 14세 미만 아동의 회원가입을 허가하지 않으며 또한 아동의 개인정보를 수집하지 않습니다.<br>
                <br>- 이용자가 개인정보의 오류에 대한 정정을 요청한 경우, 정정을 완료하기 전까지 해당 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.</p>
                <br>
                <br>
                <p class="lh6 bs4"><strong class="titleSt">4. 처리하는 개인정보의 항목 작성 </strong><br>
                <br> ① <em class="emphasis">(주)디엑스플랫폼 &gt;</em>은 다음의 개인정보 항목을 처리하고 있습니다.</p>

                <p>
                1. 회사는 회원가입, 원활한 고객상담, 각종 서비스의 제공을 위해 최초 회원가입 당시 아래와 같은 최소한의 개인정보를 필수항목으로 수집하고 있습니다.<br>
                • 이름, 생년월일, 성별, 아이디, 비밀번호, 별명, 연락처(메일주소, 휴대폰 번호 중 선택), 가입인증정보<br><br>
                2. 서비스 이용과정이나 사업처리 과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.<br>
                • IP Address, 쿠키, 방문 일시, 서비스 이용 기록, 불량 이용 기록<br><br>
                3. 유료 서비스 이용 과정에서 아래와 같은 결제 정보들이 수집될 수 있습니다.<br>
                • 신용카드 결제시 : 카드사명, 카드번호 등<br>
                • 휴대전화 결제시 : 이동전화번호, 통신사, 결제승인번호 등<br>
                • 계좌이체시 : 은행명, 계좌번호 등<br><br><br>
                회사는 다음과 같은 방법으로 개인정보를 수집합니다.<br>
                홈페이지, 팩스, 전화, 상담 게시판, 이메일
                </p>
                <br><br><br>
                <p style="font-weight:bold;font-size:15px;">＊쿠키 Cookie를 설치, 운영하고 있고 이용자는 이를 거부할 수 있습니다.</p>
                <p>
                쿠키는 이용자에게 보다 빠르고 편리한 웹사이트 사용을 지원하고 맞춤형 서비스를 제공하기 위해 사용됩니다.<br><br>
                <strong>쿠키란 무엇인가요?</strong><br>
                - 쿠키란 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에 보내는 아주 작은 텍스트 파일로서 이용자의 컴퓨터에 저장됩니다.<br><br>
                <strong>쿠키를 왜 사용하나요?</strong><br>
                - 쿠키를 통해 이용자가 선호하는 설정 등을 저장하여 이용자에게 보다 빠른 웹 환경을 지원하며, 편리한 이용을 위해 서비스 개선에 활용합니다. <br>
                이를 통해 이용자는 보다 손쉽게 서비스를 이용할 수 있게 됩니다.또한 여러 서비스의 접속 빈도, 방문 시간, 각종 이벤트 참여 정도, 방문 회수 등을 분석하여 이용자의 취향과 관심분야를 파악합니다.<br> 
                이를 바탕으로 광고를 포함한 개인 맞춤형 서비스를 제공합니다.  <br><br>
                <strong>쿠키를 수집하지 못하게 거부하고 싶다면?</strong><br>
                -이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹 브라우저에서 옵션을 설정함으로써<br>
                모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수도 있습니다.<br>
                다만 쿠키 설치를 거부할 경우 웹 사용이 불편해지며 로그인이 필요한 일부 서비스 이용에 어려움이 있을 수 있습니다.<br><br> 
                <strong>설정방법의 예</strong><br>
                1) Internet Explorer의 경우 :<br>
                　웹 브라우저 상단의 도구 메뉴 &gt; 인터넷 옵션 &gt; 개인정보 &gt; 설정<br>
                2) Chrome의 경우 :<br>
                　 웹 브라우저 우측의 설정 메뉴 &gt; 화면 하단의 고급 설정 표시 &gt; 개인정보의 콘텐츠 설정 버튼 &gt; 쿠키
                </p>
                <br><br>
                <br>
                <br>
                <p class="lh6 bs4"><strong class="titleSt">5. 개인정보의 파기</strong><br><em class="emphasis">(주)디엑스플랫폼
                </em>은 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체없이 해당 개인정보를 파기합니다. 파기의 절차, 기한 및 방법은 다음과 같습니다.</p>
                <p class="ls2">- 이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.<br>
                <br>회사 내부 방침에 의한 정보보유 사유<br>• 부정이용기록<br> 보존 이유 : 부정 이용 방지<br> 보존 기간 : 1년<br>이 때, 보존된 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.<br>
                <br>-파기기한이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내에, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다.</p>
                <p class="ls2"></p>
                <br>
                <br>
                <p class="lh6 bs4"><strong class="titleSt">6. 개인정보의 안전성 확보 조치</strong><br> <em class="emphasis">(주)디엑스플랫폼</em>은 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적/관리적 및 물리적 조치를 하고 있습니다.</p>
                <p class="ls2">
                <br>1. 개인정보 취급 직원의 최소화<br> 개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.<br>
                <br>2. 내부관리계획의 수립 및 시행<br> 개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.<br>
                <br>3. 해킹 등에 대비한 기술적 대책<br>
                <em class="emphasis">(주)디엑스플랫폼</em>은 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.<br>
                <br>4. 개인정보의 암호화<br> 이용자의 개인정보 및 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.<br>
                <br>5. 접속기록의 보관 및 위변조 방지<br> 개인정보처리시스템에 접속한 기록을 최소 6개월 이상 보관, 관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록 보안기능 사용하고 있습니다.<br>
                <br>
                </p>
                <br>
                <br>
                <p class="lh6 bs4"><strong class="titleSt">7. 개인정보 보호책임자 </strong><br> ① (주)디엑스플랫폼은 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.</p>
                <p class="ls2"><br>▶ 개인정보 보호책임자 <br>성명 : 김종호<br>직책 : 이사 <br> TEL : 02-858-3001 <br> EMAIL : jhkim@aprocni.com  <br>※ 개인정보 보호 담당부서로 연결됩니다.<br> <br>▶ 개인정보 보호 담당부서<br>부서명 :서비스운영팀<br>TEL : 02-858-3001 | MAIL : support@aprocni.com<br><br>② 정보주체께서는 (주)디엑스플랫폼의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. (주)디엑스플랫폼은 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.</p>
                <br>
                <br>

                <p class="lh6 bs4"><strong class="titleSt">8. 개인정보 처리방침 변경 </strong></p>
                <p>①이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다.</p>
                <p></p>
                <br><br><br>
            </div>
        </div>
    </div>
	
</template>

<script>
import { reactive } from '@vue/runtime-core';
import { useRouter } from 'vue-router';


export default {
  name: 'Privacy',
  props: {
    msg: String
  },

  setup(){
	  	const router = useRouter();

		const menu = reactive([
			{type : 'A', url : '/merit'},
			{type : 'B', url : '/inquiry'},
			{type : 'C', url : '/support'},
			{type : 'D', url : '/login'},
			{type : 'E', url : '/'},
		]);

	  const moveTo = (type) => {
		const url = menu.filter(item => item.type == type).map(item2 => item2.url)[0];
		
		if(url){

			if(url == '/login'){
				location.href = "https://aptok.co.kr";

			}else{
				router.push({path : url});
			}
		}
		
	};

	return {moveTo};
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
